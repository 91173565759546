<template>
  <sign-page
    title-text="我的验收单"
    :request="request"
    :column-list="columnList"
    table-size="small"
    ref="mPage"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[]"
    @tableAction="tableAction"
    :table-actions="tableActions"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="验收单明细" v-model="modelDetail" theme="mh-blackt" width="1000px">
      <div class="my-ys">
        <data-detail style="flex: 1" v-if="chooseData" :data="chooseData"></data-detail>
        <div class="ys-form" v-if="chooseData && chooseData.myDeal.length > 0 && chooseData.status === 'doing'">
          <fm-title title-text="验收意见"></fm-title>
          <div class="fm-tabs fm-tabs-norm">
            <div class="fm-tabs-nav">
              <div
                @click="dealForm.orgType = item.orgType;dealForm.content = item.content"
                class="fm-tabs-nav-item"
                :class="{'fm-tabs-nav-active': item.orgType === dealForm.orgType}"
                v-for="item in chooseData.myDeal" :key="item.orgType">
                {{item.orgLabel}}
              </div>
            </div>
          </div>
          <template v-if="dealForm.orgType && chooseData.status === 'doing'">
            <div class="ys-c-i">
              <fm-input-new style="width: 100%;" type="textarea" v-model="dealForm.content" placeholder="请输入验收意见"></fm-input-new>
            </div>
            <div class="ys-c-b">
              <fm-btn @click="submit">提交</fm-btn>
            </div>
          </template>
          <div class="ys-error" v-else-if="chooseData.status === 'doing'">
            {{!dealForm.orgType ? '无验收权限' : '未配置员工数据权限'}}
          </div>
          <div class="ys-error" v-else>
            验收已结束
          </div>
        </div>
      </div>
      <div slot="footer" class="form-btn">
        <fm-btn @click="modelDetail = false">关闭</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  acceptanceRequest as request
} from '../../api'

import dataDetail from './components/detail'
import Content from './components/content'

export default {
  components: {
    dataDetail
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'detail',
        label: '查看'
      }]
      return data
    },
    columnList () {
      return [{
        title: '',
        field: 'myEnd',
        width: 60
      },
      {
        title: '验收单编号',
        field: 'code',
        sort: true
      },
      {
        title: '验收内容',
        field: 'content',
        sort: true,
        width: 600,
        render: (h, row) => {
          return h(Content, {
            props: {
              content: row.content,
              maxWidth: 600
            }
          })
        }
      },
      {
        title: '验收日期',
        field: 'acceptanceTime',
        sort: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.acceptanceTime ? rowData.acceptanceTime.slice(0, 10) : '-')
        }
      },
      {
        title: '验收情况',
        field: 'statusLabel',
        sort: true
      }]
    }
  },
  data () {
    return {
      request: Object.assign({
        get: this.loadData
      }),
      dealForm: {
        orgType: null,
        content: null
      },
      modelDetail: false,
      chooseData: null,
      orgLabelMap: {
        use: '使用科室',
        purchase: '职能科室',
        acceptance: '固定资产管理科室'
      },
      statusList: [{key: 'draw', label: '创建中'}, {key: 'doing', label: '验收中'}, {key: 'end', label: '已验收'}],
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm && parm.length > 0 ? parm.join(',') : null
      })
      pageVm.loadData()
    },
    async submit () {
      if (!this.dealForm.content) {
        this.$notify({
          title: '系统提示',
          message: '请输入验收意见',
          type: 'info'
        })
        return
      }
      await request.accept(this.chooseData.id, Object.assign({}, this.dealForm))
      this.$notify({
        title: '系统提示',
        message: '验收完成',
        type: 'success'
      })
      await this.$refs.mPage.loadData()
      this.chooseData = this.$refs.mPage.dataList.find(v => v.id === this.chooseData.id)
    },
    tableAction (p) {
      this.chooseData = p.data
      let action = p.action
      if (action === 'detail') {
        this.dealForm = {
          orgType: null,
          content: null
        }
        if (this.chooseData.myDeal.find(v => !v.end)) {
          this.dealForm.orgType = this.chooseData.myDeal.find(v => !v.end).orgType
          this.dealForm.content = this.chooseData.myDeal.find(v => !v.end).content
        } else if (this.chooseData.myDeal.length > 0) {
          this.dealForm = {
            orgType: this.chooseData.myDeal[0].orgType,
            content: this.chooseData.myDeal[0].content
          }
        }
        this.modelDetail = true
      }
    },
    async loadData () {
      let dataList = await request.getMy()
      dataList.forEach(v => {
        v.statusLabel = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label
        v.myDeal = []
        Object.keys(this.orgLabelMap).forEach(type => {
          if (this.$store.getters.currentOrgId && this.$store.getters.currentOrgId === v[type + 'OrgId']) {
            v.myDeal.push({
              orgType: type,
              orgLabel: this.orgLabelMap[type],
              content: v[type + 'OrgContent'],
              end: v[type + 'OrgTime'] ? true : false
            })
          }
        })
        v.myEnd = v.myDeal.length === 0 ? '' : (v.myDeal.find(v1 => !v1.end) ? '未验收' : '已验收')
      })
      return dataList
    }
  }
}
</script>

<style lang="less" scoped>
.form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-ys {
  display: flex;
  .ys-form {
    width: 400px;
  }
}
.ys-c-i {
  margin-top: 20px;
}
.ys-c-b {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: flex-end;
}
.ys-error {
  padding-top: 100px;
  text-align: center;
}
</style>